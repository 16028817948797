import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Header from "../../components/Header/header"
import Footer from "../../components/Footer/footer"
import * as styles from "../../styles/product-page.module.css"
import ProductDescription from "../../components/ProductDescription/ProductDescription"

const paragraphs = [
    "New Zealand pine timber accounts for much of the industrial lumber used for the manufacture of cable drums in many countries.   Knot size is not a limiting factor for drum sides, as the board thickness can be increased and double thicknesses used in load-sharing situations on large cable drums. Relative to steel and plastic drums, wood has the advan- tages of low cost, ease of repair, workability and flexibility in terms of the size of drums that can be made.",
]

const CableDrums = () => {
    return (
        <body>
            <Header invert={true} />

            <div className={styles.pageTitle}>
                <h1>Cable Drums</h1>
            </div>

            <ProductDescription text={paragraphs}>
                <StaticImage
                    src="../../images/drum.jpg"
                    alt="Cable drum"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/drum2.jpg"
                    alt="Cable drum"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
            </ProductDescription>

            <div style={{ marginTop: "auto" }}>
                <Footer />
            </div>
        </body>
    )
}

export default CableDrums
