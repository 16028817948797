import React from "react"
import { Link } from "gatsby"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"

import * as styles from "./ProductDescription.module.css"

const ProductDescription = ({ text, imageNames, children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {text.map((paragraph, i) => {
                    return (
                        <React.Fragment key={i}>
                            <p>{paragraph}</p>
                            <br />
                        </React.Fragment>
                    )
                })}

                <div className={styles.gallery}>
                    {children}
                </div>

                <span>
                    <KeyboardBackspaceIcon
                        style={{ verticalAlign: "bottom" }}
                    />
                    <Link to="/products"> Back to products</Link>
                </span>
            </div>
        </div>
    )
}

export default ProductDescription
